import React, { useState, useEffect } from 'react';
import {
     Button
  } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BillingActiveMessage from './BillingActiveMessage';
import { loadUser } from '../../../actions/auth';
import Spinner from "../../layout-spinner/Spinner"
import { loadStripe } from '@stripe/stripe-js';
const axios = require('axios');
const axios4 = require('../../../config/axiosInstance4');
const stripePromise = loadStripe('pk_live_oYyuovMeNVaQe5SSflWCE8XD00edZonWk9');


function BillingPrices({ auth: { user, loading } }) {
    const [show, setShow] = React.useState();
    const [billing, setBilling] = React.useState("annually");
    const [country, setCountry] = React.useState("USA");
    const [stripeLoading, setstripeLoading] = useState(false);


      useEffect( () => {
        (async () => {
          var ipRes = await axios.get('https://ipapi.co/json/')
          ipRes.status === 200 ? setCountry(ipRes.data.country_name) : setCountry("Not found")
        })();
        
       
      }, [])

      const handleClickPrice1 = async (event) => {
        setstripeLoading(true)
        console.log('handleprice1')
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
      
        // const priceId1 = 'price_1I19GvJBEzpLhH7BOJqC6Drp'
        const priceId1 = country === 'India' ? 'price_1LicWNJBEzpLhH7B4z26BrTC' : 'price_1Jtp1HJBEzpLhH7BGjHNP7I1' 
        

        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var clientRefStuff
        if (window.Rewardful && window.Rewardful.referral) {
          clientRefStuff = window.Rewardful.referral;
        }
        else {
          clientRefStuff = "None"
        }
    
        var rawBody = {
            priceId: priceId1,
            slackTeamID: user.teamId,
            slackUserID: user.id,
            plan: 'trivia&basicMonthly',
            clientRefStuff: clientRefStuff
        }
        

        if(user.email !== null){
          rawBody.email = user.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);
    
        const res = await axios4.post('/api/checkoutSessionNew/triviasub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
        // early stage annually
    
      const handleClickPrice2 = async (event) => {
        setstripeLoading(true)
        console.log('handleprice2')

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        // old price
        const priceId2 = country === 'India' ? 'price_1LicUQJBEzpLhH7Bnh1fsDXh' : 'price_1JtozuJBEzpLhH7Br5RbV8Fc'
    
    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var clientRefStuff
        if (window.Rewardful && window.Rewardful.referral) {
          clientRefStuff = window.Rewardful.referral;
        }
        else {
          clientRefStuff = "None"
        }
        
        var rawBody = {
            priceId: priceId2,
              slackTeamID: user.teamId,
              slackUserID: user.id,
              plan: 'trivia&basicAnnually',
              clientRefStuff: clientRefStuff
        }

        if(user.email !== null){
          rawBody.email = user.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);
    
 
    
        const res = await axios4.post('/api/checkoutSessionNew/triviasub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
      // growth stage monthly
      const handleClickPrice3 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const priceId3 = country === 'India' ? 'price_1PnenXJBEzpLhH7B9zaclL4c' : 'price_1PnegxJBEzpLhH7B9sCNsAjg' 
    
    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var clientRefStuff
        if (window.Rewardful && window.Rewardful.referral) {
          clientRefStuff = window.Rewardful.referral;
        }
        else {
          clientRefStuff = "None"
        }

        var rawBody = {
          priceId: priceId3,
          slackTeamID: user.teamId,
          slackUserID: user.id,
          plan: 'trivia&growthMonthly',
          clientRefStuff: clientRefStuff
        }

        if(user.email !== null){
          rawBody.email = user.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);
    
    
        
    
        const res = await axios4.post('/api/checkoutSessionNew/triviasub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
      // growth stage annually
      const handleClickPrice4 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        // old price
        const priceId4 = country === 'India' ? 'price_1PneoXJBEzpLhH7B1HWfZFVE' : 'price_1PnegKJBEzpLhH7BoGef3ihZ' 
    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var clientRefStuff
        if (window.Rewardful && window.Rewardful.referral) {
          clientRefStuff = window.Rewardful.referral;
        }
        else {
          clientRefStuff = "None"
        }
        
        var rawBody = {
          priceId: priceId4,
            slackTeamID: user.teamId,
            slackUserID: user.id,
            plan: 'trivia&growthAnnually',
            clientRefStuff: clientRefStuff
        }

        if(user.email !== null){
          rawBody.email = user.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);
    
    
        const res = await axios4.post('/api/checkoutSessionNew/triviasub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
        
      // enterprise monthly
    
      const handleClickPrice5 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const priceId5 = country === 'India' ? 'price_1LicZXJBEzpLhH7BwIbKdRmY' : 'price_1JL7LTJBEzpLhH7BAsUqBtSE' 
    
    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var clientRefStuff
        if (window.Rewardful && window.Rewardful.referral) {
          clientRefStuff = window.Rewardful.referral;
        }
        else {
          clientRefStuff = "None"
        }
        
        var rawBody = {
          priceId: priceId5,
            slackTeamID: user.teamId,
            slackUserID: user.id,
            plan: 'trivia&enterpriseMonthly',
            clientRefStuff: clientRefStuff
        }

        if(user.email !== null){
          rawBody.email = user.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);

    
        const res = await axios4.post('/api/checkoutSessionNew/triviasub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };
    
      // enterprise annual
      const handleClickPrice6 = async (event) => {
        setstripeLoading(true)
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        // old price
        const priceId6 = country === 'India' ? 'price_1LicZ6JBEzpLhH7BGLCqYgcg' : 'price_1JL7MEJBEzpLhH7Bmp3tkWQk' 
    
        const configT = {
          headers: {
              'Content-Type': 'application/json',
          }
        };

        var clientRefStuff
        if (window.Rewardful && window.Rewardful.referral) {
          clientRefStuff = window.Rewardful.referral;
        }
        else {
          clientRefStuff = "None"
        }
    

        var rawBody = {
          priceId: priceId6,
            slackTeamID: user.teamId,
            slackUserID: user.id,
            plan: 'trivia&enterpriseAnnually',
        }

        if(user.email !== null){
          rawBody.email = user.email
        }
        else {
          console.log('nothing')
        }

      const body = JSON.stringify(rawBody);

    
        const res = await axios4.post('/api/checkoutSessionNew/triviasub', body, configT);
        var sessionId = res.data;
    
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
    
        
      };

      // standard monthly

      const handleClickPriceStandard1 = async (event) => {
        setstripeLoading(true)
        console.log('handlePriceStandard1')
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;

        const priceIdStandard1 = country === 'India' ? 'price_1PnepvJBEzpLhH7Bq6ETAfSh' : 'price_1PnekJJBEzpLhH7B88xQtwAG'

        const configT = {
          headers: {
            'Content-Type': 'application/json',
          }
        };

        var clientRefStuff
        if (window.Rewardful && window.Rewardful.referral) {
          clientRefStuff = window.Rewardful.referral;
        }
        else {
          clientRefStuff = "None"
        }

        var rawBody = {
          priceId: priceIdStandard1,
          slackTeamID: user.teamId,
          slackUserID: user.id,
          plan: 'trivia&standardMonthly',
          clientRefStuff: clientRefStuff
        }

        if(user.email !== null){
          rawBody.email = user.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);

        const res = await axios4.post('/api/checkoutSessionNew/triviasub', body, configT);
        var sessionId = res.data;

        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
      };

      // standard annually

      const handleClickPriceStandard2 = async (event) => {
        setstripeLoading(true)
        console.log('handlePriceStandard2')
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;

        const priceIdStandard2 = country === 'India' ? 'price_1PneqPJBEzpLhH7BWqwelBcN' : 'price_1PnekyJBEzpLhH7BoeuM0ppl'

        const configT = {
          headers: {
            'Content-Type': 'application/json',
          }
        };

        var clientRefStuff
        if (window.Rewardful && window.Rewardful.referral) {
          clientRefStuff = window.Rewardful.referral;
        }
        else {
          clientRefStuff = "None"
        }

        var rawBody = {
          priceId: priceIdStandard2,
          slackTeamID: user.teamId,
          slackUserID: user.id,
          plan: 'trivia&standardAnnually',
          clientRefStuff: clientRefStuff
        }

        if(user.email !== null){
          rawBody.email = user.email
        }
        else {
          console.log('nothing')
        }

        const body = JSON.stringify(rawBody);

        const res = await axios4.post('/api/checkoutSessionNew/triviasub', body, configT);
        var sessionId = res.data;

        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId
        });
      };
    
      const pricesEarlyGlobal = {
        monthly: 34,
        annually: 24
      }
    
      const pricesStandardGlobal = {
        monthly: 39,
        annually: 29
      }
    
      const pricesGrowthGlobal = {
        monthly: 59,
        annually: 44
      }

      const pricesEnterpriseGlobal = {
        monthly: 99,
        annually: 79
      }

      const pricesEarlyIndia = {
        monthly: 2600,
        annually: 1900
      }
    
      const pricesStandardIndia = {
        monthly: 3100,
        annually: 2300
      }
    
      const pricesGrowthIndia = {
        monthly: 4700,
        annually: 3500
      }
    
      const pricesEnterpriseIndia = {
        monthly: 7500,
        annually: 5900
      }
    
      const buttonClass1 = {
        monthly: "btn btn-first",
        annually: "btn btn-second"
      }
    
      const buttonClass2 = {
        monthly: "btn btn-second",
        annually: "btn btn-first"
      }

      

    
      function handleMonthlyChange(evt) {
          setBilling("monthly");
      }
    
      function handleAnnuallyChange(evt) {
        setBilling("annually");
      }
    
      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    
      function handlePricingButtonClick() {
        
        window.open('https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=channels:read,chat:write,chat:write.public,im:read,im:write,mpim:read,mpim:write,users:read,users:read.email&user_scope=')
      }
      
      function pricingCta(button) {
          var fn = button === 1 ? (billing === "annually" ? handleClickPrice2 : handleClickPrice1) :
            button === 2 ? (billing === "annually" ? handleClickPriceStandard2 : handleClickPriceStandard1) :
            button === 3 ? (billing === "annually" ? handleClickPrice4 : handleClickPrice3): 
            (billing === "annually" ? handleClickPrice6 : handleClickPrice5)
           

           return (<Button className="btn btn-block btn-primary text-uppercase" onClick={fn}>{stripeLoading && (
            <i
            className="fa fa-refresh fa-spin"
            style={{ marginRight: "5px" }}
            />
        )}
        {stripeLoading && <span>Loading...</span>}
        {!stripeLoading && <span>Select plan</span>}</Button>)
      }
      // var pricingCta = 
      //   (<Button className="btn btn-block btn-primary text-uppercase" onClick={handlePricingButtonClick}>{stripeLoading && (
      //     <i
      //     className="fa fa-refresh fa-spin"
      //     style={{ marginRight: "5px" }}
      //     />
      // )}
      // {stripeLoading && <span>Loading...</span>}
      // {!stripeLoading && <span>Select plan</span>}</Button>) 
        
    
      var currency = country === 'India' ? '₹' : '$'

      var annualPrice1 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesEarlyIndia["annually"]*12}/year)` : `(${currency}${pricesEarlyGlobal["annually"]*12}/year)` : ""
      var annualPrice2 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesStandardIndia["annually"]*12}/year)` : `(${currency}${pricesStandardGlobal["annually"]*12}/year)` : ""
      var annualPrice3 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesGrowthIndia["annually"]*12}/year)` : `(${currency}${pricesGrowthGlobal["annually"]*12}/year)` : ""
      var annualPrice4 = billing === 'annually' ? country === 'India' ? `(${currency}${pricesEnterpriseIndia["annually"]*12}/year)` : `(${currency}${pricesEnterpriseGlobal["annually"]*12}/year)` : ""

      var earlyPrice = country === 'India' ? numberWithCommas(pricesEarlyIndia[billing]) : numberWithCommas(pricesEarlyGlobal[billing])
      var standardPrice = country === 'India' ? numberWithCommas(pricesStandardIndia[billing]) : numberWithCommas(pricesStandardGlobal[billing])
      var growthPrice = country === 'India' ? numberWithCommas(pricesGrowthIndia[billing]) : numberWithCommas(pricesGrowthGlobal[billing])
      var enterprisePrice = country === 'India' ? numberWithCommas(pricesEnterpriseIndia[billing]) : numberWithCommas(pricesEnterpriseGlobal[billing])

      

 

    return (
        loading ? <Spinner /> : loading === false && user.isBillingActive === true ? <BillingActiveMessage user={user} /> :
        <React.Fragment>        
          <section className="pricing py-4">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class={buttonClass1[billing]} onClick={handleMonthlyChange}>Monthly</button>
                  <button type="button" class={buttonClass2[billing]} onClick={handleAnnuallyChange}>Annually</button>
                </div>
              </div> 
              <div className="mt-3 text-center">
                {/* Use code <b><span className="ricotta-color">NEWYEAR2025</span></b> to get 20% off for the first 12 months — offer ends 31st Jan 2025!  */}
                <p className="mt-0">For more than 500 users, please write to us on <b><span className="ricotta-color">cheese@ricotta.team</span></b> for a custom quote.</p>
              </div>

              {/* the below promo code was on line 566 */}
              {/* Use code <b><span className="ricotta-color">NEWYEAR2025</span></b> to get 20% off for the first 12 months — offer ends 31st Jan 2025!  */}

              {/* <div className="mt-3 text-center"><p>Black Friday Sale! Use Code <b><span className="ricotta-color">BLACKFRIDAY30</span></b> to get <b>30% off</b> on Annual Plans. Valid till <b>4th December</b> for new subscriptions!</p></div> */}
              {/* <div className="mt-3 text-center"><p>Limited time offer! Use Code <b><span className="ricotta-color">JUNE20</span></b> to get <b>20% off</b> on all plans.</p></div> */}

              {/* {country === 'India' ? 
                (<div className="mt-3 text-center">
                   Switch to Annual plans and save <b><span className="ricotta-color">20% on billing</span></b>
                  </div>) : 
                  (<div className="mt-3 text-center">
                   Switch to Annual plans and save <b><span className="ricotta-color">20% on billing</span></b>
                  </div>)}  */}
              <div className="row mt-4">
                
                <div className="col-lg-3">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Basic</h5>
                      <h6 className="card-price text-center">{currency}{earlyPrice}<span className="period">/month </span><span className="card-price-old">{annualPrice1}</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>for upto 50 users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Trivia, Photo Quiz and Weekly Trivia Contests</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Social and Ice-breaker Games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Schedule Games and Leaderboard</li>
                        <li><span className="fa-li"><i className="fa fa-times"></i></span><span className="text-muted">Daily Anagram and Weekly Connections</span></li>
                      </ul>
                      {pricingCta(1)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-3">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Standard</h5>
                      <h6 className="card-price text-center">{currency}{country === 'India' ? numberWithCommas(pricesStandardIndia[billing]) : numberWithCommas(pricesStandardGlobal[billing])}<span className="period">/month </span><span className="card-price-old">{billing === 'annually' ? country === 'India' ? `(${currency}${pricesStandardIndia["annually"]*12}/year)` : `(${currency}${pricesStandardGlobal["annually"]*12}/year)` : ""}</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>for upto 50 users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Trivia, Photo Quiz and Weekly Trivia Contests</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Social and Ice-breaker Games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Schedule Games and Leaderboard</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Daily Anagram and Weekly Connections</li>
                      </ul>
                      {pricingCta(2)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-3">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Growth</h5>
                      <h6 className="card-price text-center">{currency}{growthPrice}<span className="period">/month </span><span className="card-price-old">{annualPrice3}</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>for upto 200 users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Trivia, Photo Quiz and Weekly Trivia Contests</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Social and Ice-breaker Games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Schedule Games and Leaderboard</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Daily Anagram and Weekly Connections</li>
                      </ul>
                      {pricingCta(3)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">Enterprise</h5>
                      <h6 className="card-price text-center">{currency}{enterprisePrice}<span className="period">/month </span><span className="card-price-old">{annualPrice4}</span></h6>
                      <hr />
                      <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>for upto 500 users</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Unlimited Games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Trivia, Photo Quiz and Weekly Trivia Contests</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>All 2-player games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Social and Ice-breaker Games</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Schedule Games and Leaderboard</li>
                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Daily Anagram and Weekly Connections</li>
                      </ul>
                      {pricingCta(4)}
                      {/* <Button className="btn btn-block btn-primary text-uppercase" href="#">Sign up</Button> */}
                    </div>
                  </div>
                </div>
                
                
                
              </div>
              <div className="mt-3 text-center">
                <p className="mb-1">We offer special discounts to Educational Institutions and Non-Profits. Please write to us on <b><span className="ricotta-color">cheese@ricotta.team</span></b></p>
              </div>
            </div>
          </section>
      </React.Fragment> 
    )
}


BillingPrices.propTypes = {
  auth: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { loadUser })(BillingPrices);
