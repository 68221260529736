import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Button, Col, Navbar,
    NavbarBrand,
  } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// app.ricotta.team/trivia/billing or app.ricotta.team/trivia/dashboard

// the onSubmit part will go into pagebilling

const TriviaBilling = ({ isAuthenticated }) => {
   

    if (isAuthenticated) {
        return <Redirect to='/trivia/dashboard/index' />;
    }

    return (
    <React.Fragment> 
        <div className="maincontainer">
        <div class="container-fluid">
            <div class="row no-gutter body-film">
               
                <div class="col-md-6 d-none d-md-flex billing-bg"></div>
                
                <div class="col-md-6 cheese-bg">
                    <div class="login d-flex align-items-center py-5">
                       
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-10 col-xl-7 mx-auto">
                                  <NavbarBrand href="https://ricotta.team/trivia" target="_blank"><span><img src="/assets/cheese-512.png" width={30} alt="Ricotta" /> </span> <span style={{color: '#E04F60', fontSize: '1.2rem'}}>Ricotta Games, Trivia and Icebreakers</span></NavbarBrand>
                                    <div className ="container billing-div-3">
                                    {/* <p>NEW YEAR SALE! Use Code <b><span className="ricotta-color">NEWYEAR2025</span></b> to get <b>20% Off</b> for the first 12 months. Valid till <b>31st Jan 2025</b></p> */}
                                    <p class="text-muted mt-3 mb-1">Please confirm your Slack account again.</p>
                                    </div>
                                    <div className="mt-md-4 mt-2"><Col xs = {{size: 12}} md={{size: 12 }}><div className="col-md-12 mx-md-auto">
                                      <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=791862583680.1579546743637&scope=users:read&state=billing&redirect_uri=https://triviaapp.ricotta.team/api/auth">
                                      {/* <Button className = "btn-lg btn-block signUpButton2" href="https://slack.com/oauth/v2/authorize?client_id=2241315215093.2268033462896&scope=users:read&state=billing&redirect_uri=https://a424-106-51-141-123.ngrok.io/api/auth"> */}
                                      Confirm Slack Account</Button></div></Col></div>
                                    <div className="mt-5">
                                      <p className="text-muted">Subscribe to Ricotta to get access to:</p>
                                      <ul className="fa-ul">
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Trivia Contests</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Photo Quizzes</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Weekly Trivia</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Leaderboard</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Icebreakers</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Social Games</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Word of the day</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Daily Anagram</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>Weekly Connections</li>
                                        <li><span className="fa-li"><i className="fa fa-check"></i></span>2-player Games</li>
                                      </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </React.Fragment>  
    )
};

TriviaBilling.propTypes = {
    isAuthenticated: PropTypes.bool
  };
  
const mapStateToProps = state => ({
 isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(TriviaBilling);